.custom_table thead {
  background-color: rgba(60, 223, 31, 1) !important;
  color: black;
}

.custom_table td {
  vertical-align: middle;
  color: white;
}

.custom_table tr:hover td {
  vertical-align: middle;
  color: white !important;
}
.custom_table {
  /* font-size: 14px; */
}

.team_logo {
  height: 30px;
  width: 30px;
  display: inline-block;
  /* margin-right: 10px; */
}
.team_cell {
  /* display: flex; */
  /* flex-direction: row; */
  padding: 16px !important;
  align-items: center;
  text-align: center;
  border: none;
}

.header_container {
  margin-top: 50px;
}

.header_container span {
  display: block;
  margin-bottom: 20px;
  font-size: 16px;
  color: white;
}

.custom_button {
  /* background-color: rgba(60, 223, 31, 1) !important; */
  /* color: #fff; */
  font-weight: 500;
  padding: 5px 12px;
  /* border: none; */
  /* display: flex; */
  height: 34px;
  /* text-wrap: nowrap; */
  min-width: 100px;
  /* align-items: center; */
  justify-content: center;
  border-radius: 8888px;
}
.custom_button:hover{
  color: black !important;
}

.button_container {
  margin-top: 50px;
}

.hidden-spinner {
  visibility: hidden !important;
}

.active_custom_btn {
  border: solid 1px rgb(60,223,31); 
  display: flex;
  color: rgb(60,223,31);
  text-wrap: nowrap;
  align-items: center;
  background-color: black;
  height: 34; 
}

.active_custom_btn:hover{
  border: solid 1px white !important; 
  color: white !important;
}